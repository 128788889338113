.notfound_page__container {
  background: #000;
}

.notfound__content_container {
  align-items: center;
  animation: fadeInTop ease;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "BarlowCondensedSemiBold";
  font-size: 24px;
  height: calc(100% - 180px);
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  padding: 20px;
  width: calc(100% - 120px);
}

.notfound.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notfound.heading {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.notfound.heading h1 {
  font-size: 200px;
  font-weight: bold;
  margin: 0;
}

h2.notfound.subheading  {
  font-size: 55px;
  margin: -40px 0 0 0 ;
  text-align: center;
  width: 100%;
}

.notfound.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notfound.body p {
  font-family: "BarlowCondensedRegular";
  font-size: 24px;
  text-align: center;
}

.notfound.body .button_container {
  background: linear-gradient(45deg, #ff79f7, #fed32f);
  border-radius: 100px;
  height: fit-content;
  padding: 2px;
  width: fit-content;
}

.notfound.body .button_container a {
  align-items: center;
  background-color: #fff;
  border: solid 3px #000;
  border-radius: 100px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-family: "BarlowCondensedSemiBold";
  font-size: 20px;
  font-weight: normal;
  gap: 10px;
  letter-spacing: 2px;
  padding-left: 20px;
  text-decoration: none;
  transition: all .2s ease;
  width: fit-content;
}

.notfound.body .button_container .arrow {
  background-color: #000;
  border: solid 2px #fff;
  border-radius: 100px;
  color: #fff;
  font-size: 22px;
  padding: 10px;
}

.notfound.body .button_container a:hover {
  background-color: #000;
  color: #fff;
  transition: all .2s ease;
}

.notfound.body .button_container a:hover .arrow {
  background-color: #fff;
  border: solid 2px #000;
  color: #000;
}

.notfound_cyberorb__model canvas {
  height: 200px !important;
  width: 200px !important;
}

@media screen and (max-width: 768px) {

  .notfound.heading h1 {
    font-size: 200px;
  }

  .notfound_cyberorb__model canvas {
    height: 200px !important;
    width: 200px !important;
  }

  h2.notfound.subheading  {
    font-size: 55px;
    margin: -30px 0 0 0 ;
  }

  .notfound.body p {
    font-size: 24px;
  }

  .notfound.body .button_container a {
    font-size: 18px;
    gap: 5px !important;
  }

  .notfound.body .button_container .arrow {
    font-size: 18px !important;
  }

}


@media screen and (max-width: 480px) {

  .notfound.heading h1 {
    font-size: 150px;
  }

  .notfound_cyberorb__model canvas {
    height: 150px !important;
    width: 150px !important;
  }

  h2.notfound.subheading  {
    font-size: 40px;
    margin: -20px 0 0 0 ;
  }

  .notfound.body p {
    font-size: 22px;
  }

}

@media screen and (max-width: 400px) {

  .notfound.heading h1 {
    font-size: 125px;
  }

  .notfound_cyberorb__model canvas {
    height: 125px !important;
    width: 125px !important;
  }

  h2.notfound.subheading  {
    font-size: 35px;
    margin: -20px 0 0 0 ;
  }

}

.logo--container,
.contact__container {
  align-items: flex-end;
  display: flex;
  height: 80px;
  justify-content: center;
  position: relative;
  width: 300px;
}

.logo--container a {
  background: url('https://www.ariannaescobar.com/assets/logo.png');
  background-repeat: no-repeat;
  background-size: 95px 48.7px;
  height: 48.7px;
  margin: 0px 0px 0px 30px;
  padding: 0;
  text-indent: -999px;
  width: 95px;
}

.contact__container button {
  cursor: pointer;
}

.logo--container {
  border-radius: 20px 0px 0px 0px;
}

.contact__container {
  border-radius: 0px 20px 0px 0px;
}

.nav-left-slant,
.nav-right-slant {
  background-color: #fff;
}

.nav-left-slant svg {
  margin-left: -1px;
}

.nav-right-slant svg {
  margin-right: -1px;
}

.navbar--container {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  position: absolute;
  width: 100%;
}

nav {
  background-color: transparent;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  font-family: "BarlowCondensedSemiBold";
  font-size: 22px;
  font-weight: bold;
  height: 80px;
  letter-spacing: 2px;
  width: 100%;
}

nav ul.navbar_contents__desktop {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

}

nav a {
  color: #000;
  font-weight: normal;
  margin: 20px;
  padding: 10px 35px;
  text-decoration: none;
}

ul.navbar_contents__desktop a.active {
  background-color: #000;
  border-radius: 100px;
  color: #fff;
}

.contact__container a.nav__contact {
  background-color: #000;
  border: solid 2px #fff;
  border-radius: 100px;
  color: #fff;
  font-weight: normal;
  padding: 10px 35px;
  margin: 0px 20px 0px 0px;
  transition: all .2s ease;
}

.contact__container a.nav__contact:hover {
  background-color: #fff;
  color: #000;
}

.contact__burger_menu {
  display: none;
}

@media screen and (max-width: 1080px) {
  nav {
    font-size: 20px;
  }

  nav a {
    margin: 10px;
    padding: 10px 25px;
  }

  .logo--container a {
    background-size: 90px 46.1px;
    height: 46.1px;
    width: 90px;
  }

  .contact__container a.nav__contact {
    padding: 10px 25px;
  }
}

@media screen and (max-width: 930px) {

  .logo--container a {
    background-size: 80px 41px;
    height: 41px;
    width: 80px;
  }


  nav ul.navbar_contents__desktop li {
    display: none;
  }

  .logo--container,
  .contact__container {
    width: 200px;
  }

  .nav__contact {
    display: none;
  }

  .contact__burger_menu {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: 35px;
    margin: 0px 35px;
    position: relative;
    width: 40px;
  }

  .contact__burger_bars_container {
    display: flex;
    flex-direction: column;
    height: 35px;
    position: absolute;
    width: 40px;
    z-index: 999;
  }

  .contact__burger_menu:hover {
    cursor: pointer;
  }

  .contact__burger_bar {
    background-color: #fff;
    border-radius: 100px;
    height: 5px;
    transition: 0.2s;
    width: 100%;
  }

  .contact__burger_bars_container .contact__burger_bar:not(:last-child) {
    margin-bottom: 7px;
  }

  ul.navbar_contents__mobile {
    animation: fadeInRight ease;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;

    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    list-style: none;
    top: 70px;
    width: fit-content;
    z-index: 999;
  }

  ul.navbar_contents__mobile li {
    display: flex;
    height: fit-content;
    text-align: center;
    transition: all 0.2s ease;
    width: 100%;
  }

  ul.navbar_contents__mobile a {
    background-color: #fff;
    border: solid 1px #C4C4C4;
    display: block;
    margin: 0;
    padding: 15px 60px;
    transform: skew(-10deg);
    transition: all 0.2s ease;
    width: calc(100vw - 260px);
  }


  ul.navbar_contents__mobile a:hover,
  ul.navbar_contents__mobile a:active {
    background-color: #000;
    border: solid 1px #828282;
    box-shadow: 0px 0px 20px #000;
    color: #fff;
    padding: 30px 60px;
  }


  .navbar__shadow_filter {
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.3);
    height: calc(100vh + 20px);
    margin: -40px;
    padding: 0;
    position: fixed;
    width: calc(100vw + 20px);
    z-index: 998;
  }
}

@media screen and (max-width: 768px) {

  .contact__burger_menu {
    margin: 0px 35px;
  }

  ul.navbar_contents__mobile a {
    width: calc(100vw - 200px);
  }

  .navbar__shadow_filter {
    margin: -20px;
  }

}


@media screen and (max-width: 480px) {

  .logo--container a {
    background-size: 70px 35.9px;
    height: 35.9px;
    margin: 0px 0px 0px 25px;
    width: 70px;
  }

  nav ul.navbar_contents__desktop {
    background-color: #000;
  }

  .nav-left-slant,
  .nav-right-slant {
    display: none;
  }

  .contact__burger_menu {
    margin: 0px 27.5px;
  }

}

.works__content_container {
  align-items: center;
  display: flex;
  gap: 40px;
  height: calc(100% - 40px);
  justify-content: center;
  width: calc(100% - 130px);
}

.carousel__prev_container,
.carousel__next_container {
  border-radius: 100px;
  height: fit-content;
  padding: 2px;
  width: fit-content;
}

.carousel__prev_container {
  margin-left: 50px;
}

.carousel__next_container {
  margin-right: 50px;
}

button.carousel__cell_prev,
button.carousel__cell_next {
  align-items: center;
  background-color: #fff;
  border-radius: 100%;
  border: solid 3px #000;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  transition: all .2s ease;
}

button.carousel__cell_prev:hover,
button.carousel__cell_next:hover {
  background-color: #000;
  color: #fff;
}

button.carousel__cell_prev .arrow_icon,
button.carousel__cell_next .arrow_icon {
  font-size: 30px;
}

.carousel__container {
  align-items: center;
  animation: fadeInBottom ease;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  display: flex;
  gap: 40px;
  height: 100% !important;
  justify-content: center;
  max-height: 700px;
  overflow: hidden;
  width: 100% !important;
}

.scene {
  align-items: center;
  display: flex;
  height: calc(100% - 50px);
  justify-content: center;
  perspective: 1000px;
  position: relative;
}

.carousel {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
  width: calc(100% - 20px);
}

.carousel__cell {
  /* background: linear-gradient(45deg, #51f9ff, #e795ff); */
  backface-visibility: hidden;
  border-radius: 10px;
  color: #fff;
  font-family: "BigShouldersDisplay";
  font-weight: bold;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden;
  position: absolute;
}

.carousel__cell_content {
  background-color: #0e0e0e;
  border-radius: 10px;
  display: flex;
  font-size: 36px;
  flex-direction: column;
  height: calc(100% - 24px);
  margin: 2px;
  padding: 10px;
  width: calc(100% - 24px);
}

.carousel__cell_header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.28em;
  padding: 0.14em 0px;
  position: relative;
}

.carousel__cell_timeline {
  align-items: center;
  background-color: #000;
  border: solid 2px #ffd591;
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0.14em 0.28em;
  white-space: nowrap;
  width: auto;
}

.carousel__cell_timeline h1,
.carousel__cell_timeline h2,
.carousel__cell_techstack h1 {
  margin: 0;
}

.carousel__cell_timeline h1 {
  font-size: 1em;
  font-weight: 800;
}

.carousel__cell_timeline h2 {
  font-size: 0.7em;
  font-weight: 700;
}

.carousel__cell_headings {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.carousel__cell_title {
  font-size: 0.8em;
  margin-bottom: 0.14em;
  text-align: center;
  width: 100%;
}

.carousel__cell_techstack {
  align-items: center;
  display: flex;
  gap: 0.28em;
  justify-content: center;
}

.carousel__cell_techstack h1 {
  background-color: #fff;
  border-radius: 5px;
  color: black;
  font-size: 0.7em;
  padding: 0.14em 0.42em;
}

.carousel__cell_images {
  border: solid 2px #fff;
  border-radius: 5px;
  height: 50%;
  margin-top: 0.28em;
  overflow: hidden;
  position: relative;
  width: 100%;
}


.carousel__cell_image_buttons {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: center;
  margin: 10px 0px;
  position: relative;
  width: 100%;
}

.carousel__cell_image_buttons .arrow_icon {
  display: flex;
  font-size: 1.4em;
}

.carousel__cell_image_buttons h1 {
  border: solid 2px #fff;
  font-size: 0.61em;
  margin: 0;
  padding: 5px 10px;
}

.carousel__cell_pagination {
  display: flex;
}

button.carousel__image_prev_button,
button.carousel__image_next_button {
  background-color: transparent;
  border: solid 2px #fff;
  color: #fff;
  cursor: pointer;
  padding: 5px 10px;
  transition: all .1s ease;
}

button.carousel__image_prev_button {
  border-radius: 5px 0px 0px 5px;
  border-right: none !important;
}

button.carousel__image_next_button {
  border-left: none !important;
  border-radius: 0px 5px 5px 0px;
}

button.carousel__image_prev_button:hover,
button.carousel__image_next_button:hover,
button.carousel__image_prev_button:active,
button.carousel__image_next_button:active {
  background-color: #fff;
  color: #000;
}

button.carousel__image_prev_button.disabled,
button.carousel__image_next_button.disabled {
  transition: none;
  visibility: hidden;
}

button.carousel__image_fullscreen_button {
  align-items: center;
  background-color: #000;
  border: solid 2px #fff;
  border-radius: 5px;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 5px;
  position: absolute;
  transition: all .2s ease;
  right: 0;
}

button.carousel__image_fullscreen_button:hover,
button.carousel__image_fullscreen_button:active {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}

.carousel__cell_description {
  align-items: center;
  display: flex;
  font-size: 0.61em;
  flex-grow: 1;
  justify-content: center;
  padding: 0.28em 0px;
  text-align: center;
  width: 100%;
}

.works__carousel_buttons_mobile {
  display: none;
}

@media screen and (max-width: 1279px),
screen and (max-height: 768px) {

  .carousel__cell_content {
    font-size: 32px;
  }
}

@media screen and (max-width: 1024px) {

  .works__content_container {
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 60px);
    width: calc(100% - 60px);
  }


  .carousel__prev_container,
  .carousel__next_container {
    display: none;
  }

  .works__carousel_buttons_mobile {
    align-items: center;
    display: flex;
    gap: 80px;
    height: fit-content;
    justify-content: center;
    width: 100%;
  }

  .works__carousel_buttons_mobile .carousel__prev_container,
  .works__carousel_buttons_mobile .carousel__next_container {
    display: block;
  }

}

@media screen and (max-width: 768px) {

  .carousel__cell_content {
    font-size: 32px;
  }

}

@media screen and (max-width: 480px) {

  .carousel__cell_content {
    font-size: 30px;
  }

  .carousel__cell_header {
    flex-direction: column;
    gap: 0.3em;
  }

  .carousel__cell_timeline {
    flex-direction: row-reverse;
    gap: 0.3em;
  }

  .carousel__cell_timeline h1,
  .carousel__cell_timeline h2 {
    font-size: 1em;
  }

  .carousel__cell_images {
    height: 45%;
  }

  .carousel__cell_images .arrow_icon {
    font-size: 28px;
  }


  button.carousel__image_prev_button,
  button.carousel__image_next_button {
    padding: 5px;
  }

}

@media screen and (max-width: 380px) {

  .scene {
    height: calc(100% - 20px);
  }

  .carousel__cell_content {
    font-size: 24px;
    justify-content: center;
  }

  .carousel__cell_images {
    height: 40%;
  }

  .carousel__cell_image_buttons .arrow_icon {
    font-size: 1.2em;
  }

  .carousel__cell_description {
    flex-grow: 0;
  }

  button.carousel__cell_prev .arrow_icon, 
  button.carousel__cell_next .arrow_icon {
    font-size: 22px;
  } 

}

.home_page__container {
  background: #000;
  /* radial-gradient(circle at 70% 45%, #522662, transparent 250px),
  radial-gradient(circle at 80% 40%, #263c7d, transparent 250px); */
}

.contents--container {
  animation: borderGrowth ease;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  border-left: #fff solid 2px;
  border-right: #fff solid 2px;
  color: #fff;
  display: flex;
  font-size: 72px;
  font-family: "BigShouldersDisplay";
  height: calc(100% - 120px);
  opacity: 0;
  padding: 0px 60px;
  position: absolute;
  width: calc(100% - 240px);
}

.home-mobile--container {
  display: none;
}

.home-left--container {
  animation: fadeInLeft ease;
  animation-duration: 1.3s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  opacity: 0;
  width: 60%;
}

.home-availability--container {
  border: solid 2px #fff;
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.home-availability--text {
  align-items: center;
  font-size: 0.36em;
  font-weight: bold;
  padding: 0.54em 0em 0.54em 0.71em;
  width: 3.21em;
}

.home-availability--date {
  padding: 0.19em 0.26em 0.19em 0.13em;
  text-align: right;
}

.home-availability--text,
.home-availability--date {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-availability--date h1 {
  font-size: 1em;
  margin: 0;
}

.home-availability--date h2 {
  font-size: 0.36em;
  margin: 0;
}

.home--diagonal-line {
  width: 0.77em;
}

.home_intro__container {
  display: flex;
  flex-direction: column;
  gap: 0.38em;
}

.home_intro__container h1 {
  font-family: "BigShouldersDisplay";
  font-size: 0.83em;
  letter-spacing: 0.031em;
  margin: 0;
}

.home_intro__container p {
  font-family: "BigShouldersText";
  font-size: 0.36em;
  letter-spacing: 0.071em;
  margin: 0;
  max-width: 21.43em;
}

.home_intro__button {
  background: linear-gradient(45deg, #ff79f7, #fed32f);
  /* background: linear-gradient(45deg, #51f9ff, #e795ff); */
  border-radius: 100px;
  height: fit-content;
  padding: 0.026em;
  width: fit-content;
}

.home_intro__container a {
  align-items: center;
  background-color: #fff;
  border: solid 0.14em #000;
  border-radius: 100px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-family: "BarlowCondensedSemiBold";
  font-size: 0.28em;
  font-weight: normal;
  gap: 0.45em;
  letter-spacing: 0.09em;
  padding-left: 0.9em;
  text-decoration: none;
  transition: all .2s ease;
  width: fit-content;
}

.home_intro__arrow {
  background-color: #000;
  border: solid 0.1em #fff;
  border-radius: 100px;
  color: #fff;
  font-size: 1.25em !important;
  padding: 0.45em;
}

.home_intro__container a:hover {
  background-color: #000;
  color: #fff;
  transition: all .2s ease;
}

.home_intro__container a:hover .home_intro__arrow {
  background-color: #fff;
  border: solid 0.1em #000;
  color: #000;
}


.home-right--container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 40%;
}

/* .home-cyberorb--model {
  background: 
    radial-gradient(circle at center, #6d3283, transparent 250px),
    radial-gradient(circle at center, #3453b1, transparent 250px);
} */

.home-cyberorb--model {
  animation: fadeIn ease;
  animation-delay: 1.5s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  opacity: 0;
  height: 6.60em !important;
  width: 6.60em !important;
}

.home-positions--container {
  animation: fadeInRight ease;
  animation-delay: 1.5s;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  opacity: 0;
  width: 100%;
}

.home-positions--container h1 {
  font-size: 0.722em;
  margin: 0;
  text-align: right;
}

@media screen and (max-width: 1279px),
screen and (max-height: 868px) {

  .contents--container {
    font-size: 65px;
  }

}

@media screen and (max-width: 1279px),
screen and (max-height: 768px) {

  .contents--container {
    font-size: 56px;
  }

  .home-cyberorb--model {
    height: 5.56em !important; 
    width: 5.56em !important; 
  }

}

@media screen and (max-width: 768px) {

  .contents--container {
    align-items: center;
    height: calc(100% - 60px) !important;
    justify-content: center;
    padding: 0px 20px;
    width: calc(100% - 80px);
  }

  .home-left--container,
  .home-right--container {
    display: none;
  }

  .home-mobile--container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .home-cyberorb--model,
  .home_intro__container,
  .home-positions--container,
  .home-availability--container {
    animation: fadeIn ease;
    animation-duration: 2.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    opacity: 0;
  }

  .home-cyberorb--model {
    height: 150px !important;
    opacity: 0;
  }

  .home_intro__container {
    align-items: center;
    animation-delay: 0.4s;
    gap: 15px;
    margin: 20px 0px 35px 0px;
    text-align: center;
  }

  .home_intro__container h1 {
    font-size: 42px;
  }

  h1.home__shortened_name {
    display: none;
  }

  .home_intro__container p {
    font-size: 20px;
  }

  .home_intro__button {
    margin-top: 5px;
  }

  .home_intro__container a {
    font-size: 18px;
    gap: 5px !important;
  }

  .home_intro__arrow {
    font-size: 18px !important;
  }

  .home-positions--container {
    animation-delay: 0.8s;
    margin-bottom: 35px;
  }

  .home-positions--container h1 {
    font-size: 32px;
    text-align: center;
  }

  .home-availability--container {
    animation-delay: 1.2s;
  }

}

@media screen and (max-width: 480px) {
  h1.home__full_name {
    display: none;
  }
  
  h1.home__shortened_name {
    display: block;
  }

  .home-availability--container {
    display: none;
  }
  
}

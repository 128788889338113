.contact_page__container {
  background: #000;
}

.contact__content_container,
.contact__content_container input,
.contact__content_container textarea,
.contact__content_container button {
  font-family: "BarlowCondensedSemiBold";
  font-weight: normal;
  letter-spacing: 1px;
}

.contact__content_container {
  align-items: center;
  display: flex;
  font-size: 24px;
  gap: 50px;
  height: calc(100% - 120px);
  justify-content: center;
  overflow: hidden;
  width: calc(100% - 170px);
}

.contact_links__container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.contact_links__container h1 {
  color: #fff;
  font-family: "BigShouldersDisplay";
  font-size: 56px;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 0;
}

.contact_links__container p {
  color: #fff;
  font-family: "BigShouldersText";
  font-size: 24px;
  letter-spacing: 2px;
  margin: 0;
  max-width: 450px;
}

.contact__buttons_container {
  display: flex;
  gap: 20px;
}

.contact_links__container a {
  background: linear-gradient(45deg, #ff79f7, #fed32f);
  /* background: linear-gradient(45deg, #51f9ff, #e795ff); */
  border-radius: 100px;
  display: flex;
  height: fit-content;
  padding: 2px;
  transition: all .2s ease;
  width: fit-content;
}

.contact_links__icon {
  background-color: #fff;
  border: solid 2px #000;
  border-radius: 100px;
  color: #000;
  font-size: 28px !important;
  padding: 10px;
}

.contact_links__container a:hover .contact_links__icon {
  background-color: #000;
  color: #fff;
  transition: all .2s ease;
}

.contact_cyberorb__model canvas {
  animation: fadeInRight ease;
  animation-duration: 2.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  height: 425px !important;
  opacity: 0;
  width: 425px !important;
}

/* Smaller desktops and smaller devices
@media screen and (max-width: 1279px) {

  .contact_cyberorb__model canvas {
    height: 400px !important;
    width: 400px !important;
  }

} */

/* Tablets and smaller devices */
@media screen and (max-width: 1024px) {

  .contact_links__container {
    align-items: center;
    text-align: center;
  }

  /* .contact_cyberorb__model {
    display: none;
  } */

  .contact_cyberorb__model canvas {
    animation: fadeInBottom ease;
    animation-duration: 2.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    height: 250px !important;
    opacity: 0;
  }

  .contact__content_container {
    flex-direction: column;
    gap: 20px;
  }

}


/* Smarphones and smaller devices */
@media screen and (max-width: 768px) {

  .contact__content_container {
    width: calc(100% - 60px);
  }

  .contact_links__container h1 {
    font-size: 42px;
  }

  .contact_links__container p {
    font-size: 20px;
  }

  .contact_links__icon {
    font-size: 28px !important;
  }

  .contact_cyberorb__model canvas {
    height: 200px !important;
    opacity: 0;
  }
}

@media screen and (max-width: 480px) {

  .contact_links__container h1 {
    font-size: 40px;
  }

  .contact_cyberorb__model canvas {
    height: 150px !important;
    opacity: 0;
  }

}

.modalimage.container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  font-family: "BigShouldersDisplay";
  font-size: 32px;
  font-weight: bold;
  height: calc(100vh + 20px);
  justify-content: center;
  margin: -40px;
  padding: 0;
  position: fixed;
  width: calc(100vw + 20px);
  z-index: 9999;
}

button.modalimage.close_button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 30px;
  position: absolute;
  right: 0;
  top: 0;
}

.modalimage .close_icon {
  font-size: 36px;
}

.modalimage.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 900px;
  width: 80%;
}

.modalimage.content img {
  margin: 0px 40px;
  width: 100%;
}

.modalimage.pagination {
  display: flex;
}

.modalimage.content h1 {
  color: #fff;
  font-size: 1em;
}

.modalimage.content button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer !important;
  margin: 5px 10px;
} 

.modalimage.content button.disabled {
  visibility: hidden;
} 

@media screen and (max-width: 768px) {

  .modalimage.container {
    height: calc(100vh + 10px);
    margin: -20px;
    width: calc(100vw + 10px);
  }

  button.modalimage.close_button {
    margin: 30px 20px;
  }
  
}

@media screen and (max-width: 480px) {

  .modalimage.container {
    font-size: 28px;
  }

}

@media screen and (max-width: 380px) {

  .modalimage.container {
    font-size: 24px;
  }

}
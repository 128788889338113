.about_page__container {
  background: #000;
}

.about__content_container {
  align-items: center;
  display: flex;
  font-size: 24px;
  gap: 20px;
  height: calc(100% - 180px);
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  width: calc(100% - 120px);
}

.about__description_container {
  align-items: center;
  animation: fadeInLeft ease;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  justify-content: center;
  overflow-y: scroll;
  padding: 40px;
  width: 600px;
}

.about__description_container h1 {
  font-family: "BigShouldersDisplay";
  font-size: 56px;
  letter-spacing: 2px;
  margin: 0;
  width: 100%;
}

.about__description_container p {
  font-family: "BarlowCondensedRegular";
  font-size: 24px;
  margin: 0;
  width: 100%;
}

.about__description_container p a {
  color: #ff79f7;
  text-decoration: none;
}

.about__image_container {
  align-items: center;
  animation: fadeInRight ease;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  background: linear-gradient(45deg, #ff79f7, #fed32f);
  border-radius: 50%;
  display: flex;
  height: 350px;
  justify-content: center;
  padding: 4px;
  position: relative;
  width: 350px;
}


.about_pfp {
  aspect-ratio: 1 / 1;
  background-color: #000;
  border: solid 5px #000;
  border-radius: 50%;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
}

@media screen and (max-width: 1024px) {
  
  .about__content_container {
    flex-direction: column-reverse;
    height: calc(100% - 100px);
    width: calc(100% - 80px);
  }

  .about__description_container {
    animation: fadeInBottom ease;
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    border-radius: 0px 0px 10px 10px;
    opacity: 0;
    padding: 20px 30px;
    width: 100%;
  }

  .about__description_container h1 { 
    font-size: 50px;
  }

  .about__description_container p { 
    font-size: 26px;
  }

  .about__image_container {
    animation: fadeInTop ease;
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    height: 150px !important;
    opacity: 0;
    width: 150px !important;
  }

}

@media screen and (max-width: 768px) {

  .about__content_container {
    height: calc(100% - 60px);
    width: calc(100% - 50px);
  }
  
  .about__description_container {
    padding: 20px;
  }

  .about__description_container h1 { 
    font-size: 42px;
  }

  .about__description_container p { 
    font-size: 24px;
  }

}

@media screen and (max-width: 480px) {


  .about__content_container {
    gap: 10px;
    height: calc(100% - 40px);
  }

  .about__image_container {
    height: 300px;
  }

  .about__description_container h1 { 
    font-size: 36px;
  }

  .about__description_container p { 
    font-size: 20px;
  }

}

@media screen and (max-width: 400px) {

  .about__image_container {
    height: 250px;
  }

  .about__description_container h1 { 
    font-size: 32px;
  }

  .about__description_container p { 
    font-size: 18px;
  }

}

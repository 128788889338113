@font-face {
  font-family: "BigShouldersDisplay";
  src: url("../fonts/BigShouldersDisplay-VariableFont_wght.ttf");
}

@font-face {
  font-family: "BigShouldersText";
  src: url("../fonts/BigShouldersText-VariableFont_wght.ttf");
}

@font-face {
  font-family: "BarlowCondensedSemiBold";
  src: url("../fonts/BarlowCondensed-SemiBold.ttf");
}

@font-face {
  font-family: "BarlowCondensedRegular";
  src: url("../fonts/BarlowCondensed-Regular.ttf");
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInBottom{
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes fadeInTop{
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes borderGrowth {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: calc(100% - 120px);
  }
}


body {
  background-color: #fff;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: #000;
  border-radius: 20px;
  height: calc(100vh - 60px);
  margin: 30px;
  position: relative;
  width: calc(100vw - 60px);
}

.home_page__container,
.about_page__container,
.works_page__container,
.contact_page__container,
.notfound_page__container {
  align-items: center;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  height: calc(100% - 80px);
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 80px;
  width: 100%;
}

@media screen and (max-width: 768px) {

  .app {
    height: calc(100vh - 20px);
    margin: 10px;
    width: calc(100vw - 20px);
  }
}
